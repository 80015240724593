export const shopifyFunktion = () => {
  var scriptURL =
    'https://sdks.shopifycdn.com/buy-button/latest/buy-button-storefront.min.js'
  if (window.ShopifyBuy) {
    if (window.ShopifyBuy.UI) {
      ShopifyBuyInit()
    } else {
      loadScript()
    }
  } else {
    loadScript()
  }
  function loadScript() {
    var script = document.createElement('script')
    script.async = true
    script.src = scriptURL
    ;(
      document.getElementsByTagName('head')[0] ||
      document.getElementsByTagName('body')[0]
    ).appendChild(script)
    script.onload = ShopifyBuyInit
  }
  function ShopifyBuyInit() {
    var client = ShopifyBuy.buildClient({
      domain: 'aline-hellozh.myshopify.com',
      storefrontAccessToken: 'd7eb1ff947462e4f389c941cc69a61e6'
    })
    ShopifyBuy.UI.onReady(client).then(function(ui) {
      ui.createComponent('collection', {
        id: '268415271062',
        node: document.getElementById('collection-component-1631888449389'),
        moneyFormat: 'SFr.%20%7B%7Bamount%7D%7D',
        options: {
          product: {
            styles: {
              price: {
                display: 'none'
              },
              product: {
                width: '45%',
                'max-width': '45%', 
                'min-width': '150px',
                'margin-top': '15px',
                'margin-bottom': '15px',
                '@media (min-width: 601px)': {
                  'margin-top': '0px !important',
                  'min-width': '240px !important',
                  'max-width': 'calc(25% - 20px) !important',
                  'margin-left': '20px',
                  'margin-bottom': '50px !important',
                  width: 'calc(25% - 20px) !important',
                },
                img: {
                  'margin-bottom': '0',
                },
              },
              
              title: {
                // opacity: 0,
                color: 'white',
                'font-size': '18px', 
                "font-family": "Open Sans, sans-serif",
                "font-weight": "bold",              },
              button: {
                padding: '.5rem 2rem',
                '@media (min-width: 601px)': {
                  'padding': '17px 62px !important',
                },
                "font-family": "Open Sans, sans-serif",
                "font-weight": "bold",
                'font-size': '18px',
                "width:": "30px",
                color: '#0f46fa',
                ':hover': {
                  color: '#0f46fa',
                  'background-color': '#b5dbe6'
                },
                'background-color': '#c9f3ff',
                ':focus': {
                  'background-color': '#b5dbe6'
                },
                'border-radius': '40px',
  
              },
              quantityInput: {
                'font-size': '18px',
                'padding-top': '17px',
                'padding-bottom': '17px'
              }
            },
            text: {
              button: 'KAUFEN'
            }
          },
          productSet: {
            // iframe: false,
            styles: {
              products: {
                '@media (min-width: 601px)': {
                  'margin-left': '-20px'
                }
              }
            }
          },
          modalProduct: {
            contents: {
              img: false,
              imgWithCarousel: true,
              button: false,
              buttonWithQuantity: true
            },
            styles: {
              product: {
                '@media (min-width: 601px)': {
                  'max-width': '100%',
                  'margin-left': '0px',
                  'margin-bottom': '0px'
                }
              },
              button: {
                'font-family': 'Candara, sans-serif',
                'font-size': '18px',
                'padding-top': '17px',
                'padding-bottom': '17px',
                color: '#0f46fa',
                ':hover': {
                  color: '#0f46fa',
                  'background-color': '#b5dbe6'
                },
                'background-color': '#c9f3ff',
                ':focus': {
                  'background-color': '#b5dbe6'
                },
                'border-radius': '40px',
                'padding-left': '82px',
                'padding-right': '82px',
                 padding: '.5rem 2rem',
              },
              quantityInput: {
                'font-size': '18px',
                'padding-top': '17px',
                'padding-bottom': '17px'
              },
              title: {
                display: 'none !important',
                'font-family': 'Helvetica Neue, sans-serif',
                'font-weight': 'bold',
                'font-size': '26px',
                color: '#4c4c4c'
              }
            },
            text: {
              button: 'Add to cart'
            }
          },
          option: {},
          cart: {
            styles: {
              button: {
                'font-family': 'Candara, sans-serif',
                'font-size': '18px',
                'padding-top': '17px',
                'padding-bottom': '17px',
                color: '#0f46fa',
                ':hover': {
                  color: '#0f46fa',
                  'background-color': '#b5dbe6'
                },
                'background-color': '#c9f3ff',
                ':focus': {
                  'background-color': '#b5dbe6'
                },
                'border-radius': '40px'
              },
              title: {
                color: '#0f46fa'
              },
              header: {
                color: '#0f46fa'
              },
              lineItems: {
                color: '#0f46fa'
              },
              subtotalText: {
                color: '#0f46fa'
              },
              subtotal: {
                color: '#0f46fa'
              },
              notice: {
                color: '#0f46fa'
              },
              currency: {
                color: '#0f46fa'
              },
              close: {
                color: '#0f46fa',
                ':hover': {
                  color: '#0f46fa'
                },
                ':focus-visible': {
                  'outline': 'none'
                },
              },
              empty: {
                color: '#0f46fa'
              },
              noteDescription: {
                color: '#0f46fa'
              },
              discountText: {
                color: '#0f46fa'
              },
              discountIcon: {
                fill: '#0f46fa'
              },
              discountAmount: {
                color: '#0f46fa'
              },
              cart: {
                'background-color': '#c9f3ff'
              },
              footer: {
                'background-color': '#c9f3ff'
              }
            },
            text: {
              title: 'WARENKORB',
              total: 'Subtotal',
              empty: 'DEIN WARENKORB IST LEER',
              notice: '',
              button: 'JETZT BESTELLEN',
              noteDescription: 'BEMERKUNGEN'
            },
            popup: false
          },
          toggle: {
            styles: {
              toggle: {
                'font-family': 'Candara, sans-serif',
                'background-color': '#c9f3ff',
                ':hover': {
                  'background-color': '#b5dbe6'
                },
                ':focus': {
                  'background-color': '#b5dbe6'
                }
              },
              count: {
                'font-size': '18px',
                color: '#0f46fa',
                ':hover': {
                  color: '#0f46fa'
                }
              },
              iconPath: {
                fill: '#0f46fa'
              }
            }
          },
          lineItem: {
            styles: {
              variantTitle: {
                color: '#0f46fa'
              },
              title: {
                color: '#0f46fa'
              },
              price: {
                color: '#0f46fa'
              },
              fullPrice: {
                color: '#0f46fa'
              },
              discount: {
                color: '#0f46fa'
              },
              discountIcon: {
                fill: '#0f46fa'
              },
              quantity: {
                color: '#0f46fa'
              },
              quantityIncrement: {
                color: '#0f46fa',
                'border-color': '#0f46fa'
              },
              quantityDecrement: {
                color: '#0f46fa',
                'border-color': '#0f46fa'
              },
              quantityInput: {
                color: '#0f46fa',
                'border-color': '#0f46fa'
              }
            }
          }
        }
      })
    })
  }
}
