import React, { FC, useEffect } from 'react'
import { graphql } from 'gatsby'
import { SanityPage } from 'web/graphql-types'
import Layout from '@/containers/Layout'
import { Container, Grid, Box } from '@material-ui/core'
import ModuleLoop from '@/containers/ModulesLoop'
import { shopifyFunktion } from '@/../static/shopify'

interface ShopProps {
  data: {
    sanityPage: SanityPage
  }
}

const Shop: FC<ShopProps> = props => {
  const { id, title, meta, contentModules } = props.data.sanityPage

  useEffect(() => {
    shopifyFunktion()
  }, [])

  return (
    <Layout meta={meta}>
      <Container disableGutters maxWidth={false}>
        <Grid container direction="column">
          <Grid item>
            <ModuleLoop
              modules={contentModules?.modules?.filter(
                module => module?._type === 'moduleHeroSlider'
              )}
            />
          </Grid>
          <Grid item>
            <Box
              display="flex"
              flexDirection={{ xs: 'column-reverse', sm: 'row' }}
              maxWidth="1440px"
              justifyContent="center"
              mx={'auto'}
            >
              <Box width={{ xs: '100%', sm: '50%' }}>
                <Box
                  py={{ xs: 6, sm: 9 }}
                  pt={{ xs: 0, sm: 9 }}
                  px={{ xs: 3, sm: 0 }}
                  display="flex"
                  justifyContent="center"
                >
                  <div id="collection-component-1631888449389"></div>
                </Box>
              </Box>
              <Box width={{ xs: '100%', sm: '50%' }}>
                <Box
                  pt={{ xs: 3, sm: 9 }}
                  pl={{ xs: 0, sm: 4.5 }}
                  pr={{ xs: 0, sm: 0 }}
                  mx={{ xs: 'auto', sm: 0 }}
                  width={{ xs: '100%', sm: 'calc(50vw - 20.5rem)' }}
                  maxWidth="720px"
                >
                  <ModuleLoop
                    modules={contentModules?.modules?.filter(
                      module => module?._type !== 'moduleHeroSlider'
                    )}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Layout>
  )
}

export default Shop

export const query = graphql`
  query {
    sanityPage(title: { eq: "Buch" }) {
      id
      title
      meta {
        ...metaData
      }
      contentModules {
        ...contentModulesData
      }
    }
  }
`
